// import './App.scss';
// import React, { useState, useEffect } from 'react';
// import { Routes, Route } from "react-router-dom";
// import Layout from './Layout';
// import MainScene from './comp/MainScene';
// import PortfolioPage from './comp/PortfolioPage';
// import TalkToUs from './comp/TalkToUs';
// import { LanguageProvider } from './LanguageContext';
// import WelcomePage from './utils/WelcomePage';


// function App() {
//   const userAgent = navigator.userAgent;
//   const [showWelcome, setShowWelcome] = useState(false);

//   const root_el = document.querySelector("body");
//   root_el.addEventListener("dblclick", function (event) {
//     try {
//       if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent)) {
//         // 检查是否有元素处于全屏模式
//         if (document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement) {
//           if (document.exitFullscreen) {
//             document.exitFullscreen();
//           } else if (document.webkitExitFullscreen) { // 对于 WebKit 浏览器
//             document.webkitExitFullscreen();
//           } else if (document.mozCancelFullScreen) { // 对于 Firefox
//             document.mozCancelFullScreen();
//           } else if (document.msExitFullscreen) { // 对于 IE/Edge
//             document.msExitFullscreen();
//           }
//         } else {
//           console.log("当前未处于全屏模式，无需退出。");
//         }
//       } else {

//       }
//     } catch (error) {
//       console.log("退出全屏时发生错误：", error);
//     }
//   });

//   useEffect(() => {
//     if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent)) {
//       setShowWelcome(true);
//     };
//   }, [userAgent]);


//   function handleEnter() {
//     setShowWelcome(false);
//     const element = document.documentElement; // 获取根元素
//     if (element.requestFullscreen) {
//       element.requestFullscreen();
//     } else if (element.mozRequestFullScreen) { // Firefox
//       element.mozRequestFullScreen();
//     } else if (element.webkitRequestFullscreen) { // Chrome, Safari 和 Opera
//       element.webkitRequestFullscreen();
//     } else if (element.msRequestFullscreen) { // IE/Edge
//       element.msRequestFullscreen();
//     } else {
//       alert('您的浏览器不支持全屏模式');
//     }

//   }


//   return (
//     <LanguageProvider>
//       <div className="App">
//         {showWelcome ? <WelcomePage onEnter={handleEnter} /> : null}
//           <Layout />
//           <div className='router_container'>
//             <Routes>
//               <Route path='/' element={<MainScene />} />
//               <Route path='/portfolio' element={<PortfolioPage />} />
//               <Route path='/talktous' element={<TalkToUs />} />
//             </Routes>
//           </div>
//       </div>
//     </LanguageProvider>
//   );
// }

// export default App;





import './App.scss';
import React, { useState, useEffect } from 'react';
import { Routes, Route } from "react-router-dom";
import Layout from './Layout';
import MainScene from './comp/MainScene';
import PortfolioPage from './comp/PortfolioPage';
import TalkToUs from './comp/TalkToUs';
import { LanguageProvider } from './LanguageContext';
import WelcomePage from './utils/WelcomePage';

function App() {
  const userAgent = navigator.userAgent;
  const [showWelcome, setShowWelcome] = useState(false);

  // useEffect 中处理双击全屏逻辑
  useEffect(() => {
    const handleDoubleClick = () => {
      try {
        // 检查是否在移动设备上
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent)) {
          // 切换全屏模式
          if (document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement) {
            // 退出全屏模式
            if (document.exitFullscreen) {
              document.exitFullscreen();
            } else if (document.webkitExitFullscreen) { // WebKit
              document.webkitExitFullscreen();
            } else if (document.mozCancelFullScreen) { // Firefox
              document.mozCancelFullScreen();
            } else if (document.msExitFullscreen) { // IE/Edge
              document.msExitFullscreen();
            }
          } else {
            // 进入全屏模式
            const element = document.documentElement;
            if (element.requestFullscreen) {
              element.requestFullscreen();
            } else if (element.mozRequestFullScreen) { // Firefox
              element.mozRequestFullScreen();
            } else if (element.webkitRequestFullscreen) { // WebKit
              element.webkitRequestFullscreen();
            } else if (element.msRequestFullscreen) { // IE/Edge
              element.msRequestFullscreen();
            }
          }
        }
      } catch (error) {
        console.log("切换全屏时发生错误：", error);
      }
    };

    // 绑定双击事件监听器
    document.body.addEventListener("dblclick", handleDoubleClick);

    // 在组件卸载时移除监听器
    return () => {
      document.body.removeEventListener("dblclick", handleDoubleClick);
    };
  }, [userAgent]);

  useEffect(() => {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent)) {
      setShowWelcome(true);
    }
  }, [userAgent]);

  function handleEnter() {
    setShowWelcome(false);
    // 进入全屏模式
    const element = document.documentElement;
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.mozRequestFullScreen) { // Firefox
      element.mozRequestFullScreen();
    } else if (element.webkitRequestFullscreen) { // WebKit
      element.webkitRequestFullscreen();
    } else if (element.msRequestFullscreen) { // IE/Edge
      element.msRequestFullscreen();
    } else {
      alert('您的浏览器不支持全屏模式');
    }
  }

  return (
    <LanguageProvider>
      <div className="App">
        {showWelcome ? <WelcomePage onEnter={handleEnter} /> : null}
        <Layout />
        <div className='router_container'>
          <Routes>
            <Route path='/' element={<MainScene />} />
            <Route path='/portfolio' element={<PortfolioPage />} />
            <Route path='/talktous' element={<TalkToUs />} />
          </Routes>
        </div>
      </div>
    </LanguageProvider>
  );
}

export default App;
