import React from 'react';
import './LogoCreater.scss';
const sanitizeSvgString = (svgString) => {
    // 使用正则表达式简单移除不安全的标签或属性
    return svgString
      .replace(/<script[\s\S]*?>[\s\S]*?<\/script>/gi, '') // 移除 <script> 标签
      .replace(/on\w+="[^"]*"/gi, '')                      // 移除所有 `on*` 属性
      .replace(/href="javascript:[^"]*"/gi, '');           // 移除 href 中的 `javascript:` 语句
  };

const LogoCreater = ({svgString}) => {
    const sanitizedSvgString = sanitizeSvgString(svgString);
  return (
    <div
      className='logo_creater_container'
      dangerouslySetInnerHTML={{ __html: sanitizedSvgString }}
      aria-hidden="true" // 确保对无障碍性友好
    />
  )
}

export default LogoCreater
