import React, { useRef, useEffect } from 'react';
import './MainScene.scss';


import translations from '../translations';
import { useLanguage } from '../LanguageContext';
function MainScene() {
  const { language } = useLanguage();
  const textRef = useRef(null);

  const words = [
    "Hello",
    "This is",
    "PxDanceStudio",
    "Let's Go...",
  ];

  function setTyper(element, words) {
    const LETTER_TYPE_DELAY = 10; // 每个字母的打字延迟
    const WORD_STAY_DELAY = 2000; // 每个单词停留的延迟

    const DIRECTION_FORWARDS = 0;
    const DIRECTION_BACKWARDS = 1;

    let direction = DIRECTION_FORWARDS;
    let wordIndex = 0;
    let letterIndex = 0;
    let lastTimestamp = 0;
    let typingDelay = LETTER_TYPE_DELAY; // 当前的打字延迟

    function typeLetter(timestamp) {
      if (!lastTimestamp) lastTimestamp = timestamp;
      const deltaTime = timestamp - lastTimestamp;

      if (deltaTime >= typingDelay) {
        const word = words[wordIndex];

        if (direction === DIRECTION_FORWARDS) {
          letterIndex++;
          if (letterIndex === word.length) {
            direction = DIRECTION_BACKWARDS;
            typingDelay = WORD_STAY_DELAY; // 单词打完后，停留3秒
          }
        } else if (direction === DIRECTION_BACKWARDS) {
          letterIndex--;
          typingDelay = LETTER_TYPE_DELAY; // 删除时恢复到打字速度
          if (letterIndex === 0) {
            nextWord();
          }
        }

        // 更新显示的文本
        const textToType = word.substring(0, letterIndex);
        element.textContent = textToType;

        lastTimestamp = timestamp; // 更新最后的时间戳
      }

      // 请求下一帧
      requestAnimationFrame(typeLetter);
    }

    function nextWord() {
      letterIndex = 0;
      direction = DIRECTION_FORWARDS;
      wordIndex++;
      if (wordIndex === words.length) {
        wordIndex = 0; // 循环回到第一个单词
      }
    }

    // 启动打字效果
    requestAnimationFrame(typeLetter);
  }

  useEffect(() => {
    // 延迟 1 秒后启动打字机效果
    const delayTimeout = setTimeout(() => {
      if (textRef.current) {
        setTyper(textRef.current, words);
      }
    }, 1000); // 延迟1秒
    return () => clearTimeout(delayTimeout);
  },[]);

  return (
    <div className='mainScene-container'>
      <div className='typewriter-container'>
        <span ref={textRef} className="typing-text"></span>
        <span className='slogan'>{translations[language].slogan}</span>
      </div>
    </div>
  );
}

export default MainScene;
