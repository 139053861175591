import React, { createContext, useState, useContext } from 'react';

// 创建语言上下文
const LanguageContext = createContext();

// 默认语言（可以选择 'en' 或 'zh' 等）
const DEFAULT_LANGUAGE = 'en';

// 创建语言提供者组件
export const LanguageProvider = ({ children }) => {
    // 初始化语言状态
    const [language, setLanguage] = useState(() => {
      // 尝试从 localStorage 获取语言信息
      const savedLanguage = localStorage.getItem('appLanguage');
      return savedLanguage ? savedLanguage : DEFAULT_LANGUAGE;
    });
  
    // 切换语言的方法
    const toggleLanguage = (lang) => {
      setLanguage(lang);
      localStorage.setItem('appLanguage', lang); // 更新 localStorage 中的语言信息
    };
  
    return (
      <LanguageContext.Provider value={{ language, toggleLanguage }}>
        {children}
      </LanguageContext.Provider>
    );
  };
  
  // 创建一个自定义 Hook 来简化语言上下文的使用
  export const useLanguage = () => useContext(LanguageContext);