const translations = {
    en: {
      welcome_button_text: "Let's start",
      welcome_tip: 'Double-tap to enter/exit full screen.',
      slogan: 'Trust in complexity, future in creativity.',
      current_language: 'English',
      current_theme_light: 'Light',
      current_theme_dark: 'Dark',
      theme: 'Light/Dark',
      router_home: 'Home',
      router_profile: 'Profile',
      router_us: 'Us',
      profile_logo: 'LOGO',
      profile_poster: 'Poster',
      profile_banner: 'Banner',
      profile_long_poster: 'Long Poster',
      profile_creative: 'Creative',
    },
    zh: {
      welcome_button_text: "出发",
      welcome_tip: '双击屏幕进入/退出全屏',
      slogan: '在复杂中构建信任，于创意中勾勒未来',
      current_language: '简体中文',
      current_theme_light: '浅色',
      current_theme_dark: '深色',
      theme: '浅色/深色',
      router_home: '首页',
      router_profile: '作品',
      router_us: '我们',
      profile_logo: 'LOGO设计',
      profile_poster: '海报',
      profile_banner: 'Banner设计',
      profile_long_poster: '长图',
      profile_creative: '创意',
    },
  };
  
  export default translations;
  