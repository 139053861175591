
import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import './Layout.scss';
import translations from './translations';
import { useLanguage } from './LanguageContext';

function Layout() {
  // 语言切换
  const { language, toggleLanguage } = useLanguage();

  const [isOpen, setIsOpen] = useState(false);
  const [theme, setTheme] = useState('light');
  function handleClick() {
    // 切换菜单显示
    setIsOpen(!isOpen);
  }

  useEffect(() => {
    const savedTheme = localStorage.getItem('theme') || 'light';
    setTheme(savedTheme);
    document.documentElement.setAttribute('data-theme', savedTheme);

  }, []);

  function handleLanguageChange() {
    // 切换语言
    toggleLanguage(language === 'en' ? 'zh' : 'en');
    setIsOpen(false);
  }

  function handleThemeChange() {
    const currentTheme = document.documentElement.getAttribute('data-theme');
    const newTheme = currentTheme === 'dark' ? 'light' : 'dark';
    document.documentElement.setAttribute('data-theme', newTheme);
    localStorage.setItem('theme', newTheme);
    setTheme(newTheme);
    setIsOpen(false);
  }

  return (
    <div className={`layout ${isOpen ? 'layout-open' : ''}`}>
      <div className='logo'>
        <NavLink to="/" onClick={() => setIsOpen(false)}>

          <div className='logo'>
            <svg x="0px" y="0px" width="125px" height="20px" viewBox="0 0 125 20">
              <g>
                <path className="st0" d="M6.7,18.9H0l0.5-0.4C0.8,18.2,1,18,1.1,17.8c0.1-0.3,0.2-1.2,0.2-2.6c0-1.2-0.1-2.9-0.2-5.2
c0.1-2.3,0.2-4,0.2-5.2c0-1.4-0.1-2.2-0.2-2.6C1,2,0.8,1.8,0.5,1.5L0,1.1h8.9c1.3,0,2.4,0.2,3.3,0.6c0.9,0.4,1.6,0.9,2.2,1.6
c0.8,1,1.2,2.1,1.2,3.4c0,1.2-0.4,2.4-1.2,3.4c-0.5,0.7-1.3,1.2-2.2,1.6c-0.9,0.4-2,0.6-3.3,0.6H5.4c0,1.1-0.1,2.2-0.1,3.3
c0,1.1,0.1,1.9,0.2,2.2c0.1,0.2,0.3,0.4,0.6,0.7L6.7,18.9z M8.9,7.8c0.7,0,1.3-0.1,1.7-0.3c0.4-0.2,0.6-0.5,0.6-0.8
c0-0.3-0.2-0.5-0.6-0.8c-0.4-0.2-0.9-0.4-1.7-0.4H5.4c0,0.8,0,1.5,0.1,2.2H8.9z"/>
                <path className="st0" d="M34.4,18.9h-6.7l0.5-0.4c0.3-0.3,0.5-0.5,0.6-0.7c0.1-0.3,0.2-0.8,0.2-1.4c0-0.5-0.1-1.2-0.2-2L26,12.6
l-2.8,1.9c-0.1,0.8-0.2,1.5-0.2,2c0,0.5,0.1,1,0.2,1.3c0.1,0.2,0.3,0.4,0.6,0.7l0.5,0.4h-6.7l0.5-0.4c0.3-0.3,0.5-0.5,0.6-0.7
c0.1-0.3,0.2-1,0.2-2c0-0.9-0.1-2.1-0.2-3.6l3.3-2.2l-3.3-2.2C18.9,6.3,19,5.1,19,4.2c0-1-0.1-1.6-0.2-2c-0.1-0.2-0.3-0.4-0.6-0.7
l-0.5-0.4h6.7l-0.5,0.4c-0.3,0.3-0.5,0.5-0.6,0.7C23.1,2.6,23,3,23,3.5c0,0.2,0,0.5,0,0.7l0.2,1.3L26,7.4l2.8-1.9
c0.1-0.8,0.2-1.4,0.2-2c0-0.6-0.1-1-0.2-1.4c-0.1-0.2-0.3-0.4-0.6-0.7l-0.5-0.4h6.7l-0.5,0.4c-0.3,0.3-0.5,0.5-0.6,0.7
c-0.1,0.3-0.2,1-0.2,2c0,0.9,0.1,2.1,0.2,3.6L29.9,10l3.3,2.2c-0.1,1.5-0.2,2.7-0.2,3.6c0,1,0.1,1.6,0.2,2c0.1,0.2,0.3,0.4,0.6,0.7
L34.4,18.9z"/>
                <path className="st0" d="M43.7,18.9h-7.8l0.5-0.4c0.3-0.3,0.5-0.5,0.6-0.7c0.1-0.4,0.2-1.2,0.2-2.5c0-0.6,0-1.2,0-1.8L37,10
c0.1-2.4,0.2-4.1,0.2-5.3c0-1.3-0.1-2.1-0.2-2.5c-0.1-0.2-0.3-0.4-0.6-0.7l-0.5-0.4h7.8c1.5,0,2.8,0.3,3.9,0.8s2,1.2,2.8,2
c0.7,0.8,1.3,1.8,1.7,2.9s0.6,2.1,0.6,3.3s-0.2,2.2-0.6,3.3s-0.9,2-1.7,2.9c-0.7,0.8-1.7,1.5-2.8,2S45.2,18.9,43.7,18.9z
M43.7,14.4c0.8,0,1.6-0.2,2.2-0.5c0.6-0.3,1.1-0.7,1.4-1.3c0.5-0.8,0.8-1.7,0.8-2.7c0-1-0.3-1.9-0.8-2.7c-0.4-0.5-0.8-1-1.4-1.3
c-0.6-0.3-1.3-0.5-2.2-0.5h-2.4c0,1.4,0.1,2.9,0.2,4.4c-0.1,1.6-0.2,3.1-0.2,4.4H43.7z"/>
                <path className="st0" d="M71.4,18.9h-6.7c0.1-0.2,0.2-0.5,0.2-0.9c0-0.6-0.1-1.4-0.3-2.4h-1.1l-1.1,1.1l-1.1-1.1h-1.1
c-0.2,1-0.3,1.8-0.3,2.4c0,0.5,0.1,0.8,0.2,1h-6.7c0.2-0.1,0.4-0.2,0.6-0.5c0.2-0.2,0.3-0.6,0.6-1.2c0.2-0.6,0.5-1.5,0.9-2.7
c0.7-2.2,2-6.1,4-11.8c0-0.1,0-0.1,0-0.2c0-0.3-0.3-0.8-1-1.5h7.7l-0.2,0.2c-0.6,0.5-0.9,0.9-0.9,1.3c0,0.1,0,0.1,0,0.2
c2,5.7,3.3,9.6,4,11.8c0.4,1.2,0.7,2.1,0.9,2.7s0.4,1,0.6,1.2S71.2,18.8,71.4,18.9z M62.5,11.9c0.4,0,0.9,0,1.3-0.1
c-0.4-1.5-0.8-3-1.3-4.4c-0.5,1.5-0.9,2.9-1.3,4.4C61.6,11.9,62.1,11.9,62.5,11.9z"/>
                <path className="st0" d="M89.6,18.9h-5.6c0-0.5-0.4-1.3-1.1-2.3c-0.7-1.1-1.6-2.2-2.6-3.5s-2-2.5-3-3.7l0,0.7c-0.1,2.2-0.2,4-0.2,5.2
c0,1.4,0.1,2.2,0.2,2.6c0.1,0.2,0.3,0.4,0.6,0.7l0.5,0.4h-6.7l0.5-0.4c0.3-0.3,0.5-0.5,0.6-0.7c0.1-0.3,0.2-1.2,0.2-2.6
c0-1.2-0.1-3-0.2-5.2c0.1-2.2,0.2-4,0.2-5.2c0-1.4-0.1-2.2-0.2-2.6c-0.1-0.2-0.3-0.4-0.6-0.7l-0.5-0.4h5.6c0,0.5,0.4,1.3,1.1,2.3
c0.7,1.1,1.6,2.2,2.6,3.5c1,1.3,2,2.5,3,3.7l0-0.7c0.1-2.2,0.2-4,0.2-5.2c0-1.4-0.1-2.2-0.2-2.6C84,2,83.8,1.8,83.5,1.5L83,1.1h6.7
l-0.5,0.4c-0.3,0.3-0.5,0.5-0.6,0.7c-0.1,0.3-0.2,1.2-0.2,2.6c0,1.2,0.1,3,0.2,5.2c-0.1,2.2-0.2,4-0.2,5.2c0,1.4,0.1,2.2,0.2,2.6
c0.1,0.2,0.3,0.4,0.6,0.7L89.6,18.9z"/>
                <path className="st0" d="M100.1,19c-1.2,0-2.3-0.1-3.1-0.4s-1.6-0.6-2.2-1.1c-0.6-0.4-1.1-0.9-1.5-1.5c-0.4-0.6-0.7-1.1-1-1.7
s-0.5-1.1-0.7-1.7c-0.2-0.6-0.4-1.1-0.7-1.6s-0.5-0.8-0.8-0.9c0.3-0.2,0.6-0.5,0.8-0.9s0.4-1,0.7-1.6C91.8,7,92,6.5,92.3,5.9
s0.6-1.2,1-1.7c0.4-0.6,0.9-1,1.5-1.5c0.6-0.4,1.3-0.8,2.2-1.1s1.9-0.4,3.1-0.4c0.8,0,1.5,0,2.3,0.1s1.4,0.2,2,0.3
c0.6,0.1,1.1,0.3,1.5,0.4c0.3-0.2,0.5-0.6,0.9-1.1v6.9l-0.3-0.2c-0.5-0.4-1.2-0.8-2.1-1.2c-1.3-0.5-2.7-0.8-4.3-0.8
c-0.8,0-1.6,0.2-2.2,0.5c-0.6,0.3-1.1,0.8-1.5,1.3c-0.5,0.8-0.8,1.7-0.8,2.7c0,1,0.3,1.9,0.8,2.7c0.4,0.5,0.9,1,1.5,1.3
s1.3,0.5,2.2,0.5c2,0,3.7-0.4,5.1-1.2c1.1-0.6,1.9-1.2,2.4-1.8l0.3-0.3v6.9c-0.3-0.4-0.5-0.7-0.8-1c-0.5,0.2-1.1,0.5-1.9,0.8
s-1.6,0.5-2.5,0.8S100.9,19,100.1,19z"/>
                <path className="st0" d="M125,20l-0.1-0.2c-0.1-0.1-0.2-0.2-0.3-0.4c-0.3-0.4-0.5-0.5-0.7-0.6c-2.4,0-4.8-0.1-7.2-0.1
c-2.4,0-4.8,0-7.2,0.1l0.5-0.4c0.3-0.3,0.5-0.5,0.6-0.7c0.1-0.4,0.2-1.2,0.2-2.5c0-0.6,0-1.2,0-1.8l-0.2-3.5
c0.1-2.4,0.2-4.1,0.2-5.3c0-1.3-0.1-2.1-0.2-2.5c-0.1-0.2-0.3-0.4-0.6-0.7l-0.5-0.4c2.4,0,4.8,0.1,7.2,0.1c2.4,0,4.8,0,7.2-0.1
c0.2,0,0.5-0.2,0.7-0.6L125,0v6.7l-0.1-0.2c-0.5-0.6-0.9-0.9-1.2-1c-1.5,0-3-0.1-4.4-0.1c-1.5,0-3,0-4.4,0.1c0,0.8,0,1.5,0.1,2.2
c1.1,0,2.3,0.1,3.4,0.1s2.3,0,3.4-0.1c0.2,0,0.4-0.2,0.7-0.6l0.4-0.6v6.7c-0.5-0.7-0.8-1.1-1.1-1.1c-1.1,0-2.3-0.1-3.4-0.1
s-2.3,0-3.4,0.1c0,0.7-0.1,1.4-0.1,2.2c1.5,0,3,0.1,4.5,0.1s3,0,4.5-0.1c0.2,0,0.4-0.2,0.7-0.6l0.4-0.5V20z"/>
              </g>
            </svg>

          </div>
        </NavLink>
      </div>

      {/* 导航栏部分 */}
      <nav className={`nav ${isOpen ? 'open' : ''}`}>
        <NavLink to="/" onClick={() => setIsOpen(false)}>
          <div className='nav-title'>
            <span>{translations[language].router_home}</span>
          </div>
        </NavLink>
        <NavLink to="/portfolio" onClick={() => setIsOpen(false)}>
          <div className='nav-title'>
            <span>{translations[language].router_profile}</span>
          </div>
        </NavLink>
        <NavLink to="/talktous" onClick={() => setIsOpen(false)}>
          <div className='nav-title'>
            <span>{translations[language].router_us}</span>
          </div>
        </NavLink>
        {/* 语言切换按钮 */}
        <div onClick={handleLanguageChange} className='language-toggle'>
          <span>{translations[language].current_language}</span>
        </div>
        {/* 主题切换按钮 */}
        <div onClick={handleThemeChange} className='language-toggle'>
          <span className='language-toggle-text'>{theme === 'dark' ? translations[language].theme : translations[language].theme}</span>
        </div>
      </nav>

      {/* 汉堡按钮 */}
      <div className='hamburger_btn'>
        <button onClick={handleClick} type='button' className={`hamburger h-4 ${isOpen ? 'hamburger-active' : ''}`} title='Toggle menu' aria-label="Toggle menu">
          <span></span>
          <span></span>
          <span></span>
        </button>
      </div>
    </div>
  );
}

export default Layout;


