import './WelcomePage.scss';
import translations from '../translations';
import { useLanguage } from '../LanguageContext';
const WelcomePage = ({ onEnter }) => {
  const { language } = useLanguage();
  return (
    <div className='welcome-container'>

      <svg x="0px" y="0px" width="200px" height="32px" viewBox="0 0 200 32">
        <g>
          <path className="st0" d="M10.7,30.2H0l0.8-0.7c0.5-0.4,0.8-0.8,1-1.1c0.2-0.6,0.3-1.9,0.3-4.1c0-1.9-0.1-4.6-0.3-8.3
		C2,12.3,2.1,9.6,2.1,7.7c0-2.2-0.1-3.6-0.3-4.1c-0.1-0.3-0.5-0.7-1-1.1L0,1.8h14.2c2,0,3.8,0.3,5.2,1s2.6,1.5,3.5,2.5
		c1.3,1.6,2,3.4,2,5.4c0,2-0.7,3.8-2,5.4c-0.9,1.1-2,1.9-3.5,2.6c-1.4,0.6-3.2,1-5.2,1H8.7c-0.1,1.8-0.1,3.5-0.1,5.3
		c0,1.8,0.1,3,0.3,3.6c0.1,0.2,0.4,0.6,1,1.1L10.7,30.2z M14.2,12.4c1.2,0,2.1-0.2,2.7-0.6c0.6-0.4,0.9-0.8,0.9-1.2
		c0-0.4-0.3-0.8-0.9-1.2c-0.6-0.4-1.5-0.6-2.7-0.6H8.6c0,1.2,0.1,2.4,0.1,3.6H14.2z"/>
          <path className="st0" d="M55,30.2H44.3l0.8-0.7c0.5-0.5,0.9-0.9,1-1.1c0.2-0.5,0.3-1.2,0.3-2.2c0-0.9-0.1-1.9-0.3-3.1l-4.4-3l-4.4,3
		c-0.2,1.3-0.3,2.4-0.3,3.2c0,0.9,0.1,1.6,0.3,2.1c0.1,0.3,0.5,0.6,1,1.1l0.8,0.7H28.3l0.8-0.7c0.5-0.5,0.9-0.9,1-1.1
		c0.2-0.5,0.3-1.6,0.3-3.2c0-1.4-0.1-3.3-0.3-5.7l5.3-3.6l-5.3-3.5c0.2-2.4,0.3-4.3,0.3-5.7c0-1.6-0.1-2.6-0.3-3.2
		c-0.1-0.2-0.4-0.6-1-1.1l-0.8-0.7H39l-0.8,0.7c-0.5,0.5-0.8,0.8-1,1.1c-0.2,0.5-0.3,1.2-0.3,2.1c0,0.4,0,0.8,0,1.2l0.3,2.1l4.4,3
		l4.4-3c0.2-1.3,0.3-2.3,0.3-3.1c0-0.9-0.1-1.7-0.3-2.2c-0.1-0.2-0.4-0.6-1-1.1l-0.8-0.7H55l-0.8,0.7c-0.5,0.5-0.9,0.9-1,1.1
		c-0.2,0.5-0.3,1.6-0.3,3.2c0,1.4,0.1,3.3,0.3,5.7L47.9,16l5.3,3.6c-0.2,2.4-0.3,4.3-0.3,5.7c0,1.6,0.1,2.6,0.3,3.2
		c0.1,0.2,0.4,0.6,1,1.1L55,30.2z"/>
          <path className="st0" d="M69.9,30.2H57.5l0.8-0.7c0.5-0.5,0.8-0.8,1-1.1c0.2-0.6,0.3-1.9,0.3-4c0-0.9,0-1.9,0-2.9L59.3,16
		c0.2-3.8,0.3-6.6,0.3-8.5c0-2.1-0.1-3.4-0.3-4c-0.2-0.3-0.5-0.7-1-1.1l-0.8-0.7h12.4c2.4,0,4.5,0.4,6.2,1.2s3.3,1.9,4.4,3.2
		s2.1,2.9,2.7,4.6c0.6,1.7,0.9,3.4,0.9,5.2s-0.3,3.5-0.9,5.2c-0.6,1.7-1.5,3.2-2.7,4.6s-2.7,2.4-4.4,3.2S72.3,30.2,69.9,30.2z
		 M69.9,23.1c1.4,0,2.5-0.3,3.5-0.8c1-0.5,1.7-1.2,2.3-2C76.6,19,77,17.6,77,16c0-1.6-0.4-3-1.3-4.3c-0.6-0.8-1.4-1.5-2.3-2
		c-1-0.5-2.1-0.8-3.5-0.8h-3.8c0,2.2,0.1,4.6,0.3,7.1c-0.2,2.5-0.3,4.9-0.3,7.1H69.9z"/>
          <path className="st0" d="M114.2,30.2h-10.7c0.2-0.2,0.4-0.7,0.4-1.5c0-1-0.1-2.3-0.4-3.9h-1.7l-1.8,1.8l-1.8-1.8h-1.7
		c-0.3,1.6-0.5,2.8-0.5,3.8c0,0.8,0.1,1.3,0.4,1.6H85.8c0.4-0.1,0.7-0.4,0.9-0.7c0.3-0.4,0.6-1,0.9-2s0.8-2.4,1.4-4.3
		c1.1-3.5,3.2-9.8,6.4-18.9c0-0.1,0-0.2,0-0.3c0-0.5-0.6-1.3-1.7-2.3h12.2L105.8,2c-0.9,0.8-1.4,1.5-1.4,2.1c0,0.1,0,0.2,0,0.3
		c3.2,9.1,5.3,15.4,6.4,18.9c0.6,1.9,1.1,3.3,1.4,4.3c0.3,0.9,0.6,1.6,0.9,2C113.5,29.8,113.8,30.1,114.2,30.2z M100,19.1
		c0.7,0,1.4-0.1,2.1-0.2c-0.6-2.4-1.3-4.8-2.1-7.1c-0.7,2.3-1.4,4.7-2.1,7.1C98.6,19,99.3,19.1,100,19.1z"/>
          <path className="st0" d="M143.4,30.2h-8.9c0-0.8-0.6-2-1.7-3.7c-1.1-1.7-2.5-3.6-4.1-5.6c-1.6-2.1-3.3-4-4.8-5.9l0.1,1.1
		c-0.2,3.6-0.3,6.3-0.3,8.3c0,2.2,0.1,3.6,0.3,4.1c0.1,0.2,0.4,0.6,1,1.1l0.8,0.7H115l0.8-0.7c0.5-0.5,0.9-0.8,1-1.1
		c0.2-0.6,0.3-1.9,0.3-4.1c0-2-0.1-4.8-0.3-8.3c0.2-3.6,0.3-6.3,0.3-8.3c0-2.2-0.1-3.6-0.3-4.1c-0.1-0.3-0.4-0.6-1-1.1L115,1.8h8.9
		c0,0.8,0.6,2,1.7,3.7s2.5,3.6,4.1,5.6c1.6,2.1,3.3,4,4.8,5.9l-0.1-1c0.2-3.6,0.3-6.3,0.3-8.3c0-2.2-0.1-3.6-0.3-4.1
		c-0.1-0.3-0.4-0.6-1-1.1l-0.8-0.7h10.7l-0.8,0.7c-0.5,0.5-0.9,0.9-1,1.1c-0.2,0.5-0.3,1.9-0.3,4.1c0,2,0.1,4.8,0.3,8.3
		c-0.2,3.6-0.3,6.3-0.3,8.3c0,2.2,0.1,3.6,0.3,4.1c0.1,0.2,0.4,0.6,1,1.1L143.4,30.2z"/>
          <path className="st0" d="M160.1,30.4c-2,0-3.6-0.2-5-0.6c-1.4-0.4-2.6-1-3.5-1.7c-1-0.7-1.8-1.5-2.4-2.4c-0.6-0.9-1.2-1.8-1.6-2.7
		c-0.4-0.9-0.8-1.8-1.1-2.7c-0.3-1-0.7-1.8-1.1-2.5c-0.4-0.7-0.8-1.2-1.3-1.5c0.5-0.3,1-0.8,1.3-1.5c0.4-0.7,0.7-1.6,1.1-2.6
		c0.3-0.9,0.7-1.8,1.1-2.7c0.4-0.9,0.9-1.8,1.6-2.7s1.4-1.7,2.4-2.4c1-0.7,2.1-1.3,3.5-1.7c1.4-0.4,3.1-0.6,5-0.6
		c1.2,0,2.4,0.1,3.6,0.2c1.2,0.2,2.3,0.3,3.2,0.5c1,0.2,1.8,0.4,2.4,0.6c0.4-0.4,0.9-0.9,1.4-1.7v11l-0.4-0.4
		c-0.8-0.7-1.9-1.3-3.4-1.9c-2-0.8-4.3-1.3-6.8-1.3c-1.3,0-2.5,0.3-3.5,0.8c-1,0.5-1.7,1.2-2.3,2c-0.9,1.3-1.3,2.7-1.3,4.3
		c0,1.6,0.4,3,1.3,4.3c0.6,0.8,1.4,1.5,2.3,2c1,0.5,2.1,0.8,3.5,0.8c3.2,0,5.9-0.6,8.2-1.9c1.7-0.9,3-1.9,3.8-2.9l0.4-0.5v11
		c-0.5-0.7-0.9-1.2-1.2-1.5c-0.8,0.4-1.8,0.8-3,1.2c-1.2,0.5-2.6,0.9-4,1.2S161.5,30.4,160.1,30.4z"/>
          <path className="st0" d="M200,32l-0.2-0.3c-0.1-0.2-0.3-0.4-0.4-0.6c-0.5-0.6-0.9-0.9-1.2-0.9c-3.9-0.1-7.7-0.1-11.6-0.1
		c-3.9,0-7.7,0-11.6,0.1l0.8-0.7c0.5-0.5,0.8-0.8,1-1.1c0.2-0.6,0.3-1.9,0.3-4c0-0.9,0-1.9,0-2.9l-0.3-5.6c0.2-3.8,0.3-6.6,0.3-8.5
		c0-2.1-0.1-3.4-0.3-4c-0.2-0.3-0.5-0.7-1-1.1l-0.8-0.7c3.9,0.1,7.7,0.1,11.6,0.1c3.9,0,7.7,0,11.6-0.1c0.3,0,0.7-0.3,1.2-0.9L200,0
		v10.7l-0.2-0.3c-0.8-1-1.4-1.5-1.8-1.5c-2.4-0.1-4.7-0.1-7.1-0.1c-2.4,0-4.7,0-7.1,0.1c0,1.2,0.1,2.4,0.1,3.6
		c1.8,0.1,3.6,0.1,5.4,0.1s3.6,0,5.4-0.1c0.3,0,0.7-0.3,1.2-0.9l0.6-0.9v10.7c-0.7-1.1-1.3-1.7-1.8-1.8c-1.8-0.1-3.6-0.1-5.4-0.1
		s-3.6,0-5.4,0.1c-0.1,1.1-0.1,2.3-0.1,3.5c2.4,0.1,4.8,0.1,7.3,0.1c2.4,0,4.8,0,7.3-0.1c0.3,0,0.7-0.3,1.2-0.9l0.6-0.9V32z"/>
        </g>
      </svg>

      <div className='welcome-button' onClick={onEnter}>
        <span className='welcome-button-text'>{translations[language].welcome_button_text}</span>
      </div>
      <span className='welcome-tip'>{translations[language].welcome_tip}</span>
    </div>
  );
};

export default WelcomePage;