const LOGOS = [
	{
		logo: `
		<svg x="0px" y="0px" width="122.1px" height="129px" viewBox="0 0 122.1 129">
		<path class="logos_svg" d="M92.6,95.8c-1.8-1.8-3.5-3.7-5-5.7c7.1,1.3,13.3,5.1,17.7,10.4c8.5-10.2,14.4-22.6,16.8-36.2C103.5,58,88,44.9,78.6,28
			c-4.7-8.5-7.9-17.9-9.2-28c-3.6,10.4-5.8,21.5-6.6,32.9c-0.2,2.7-0.3,5.5-0.3,8.3c0,1.7,0,3.3,0.1,4.9c7,2.5,12,9.2,12,17.1
			c0,5.8-2.7,10.9-6.9,14.2c-1.9,1.5-4.2,2.7-6.7,3.3c-2.5-0.6-4.7-1.8-6.7-3.3c-4.2-3.3-6.9-8.5-6.9-14.2c0-7.9,5-14.6,12-17.1
			c0.1-1.6,0.1-3.3,0.1-4.9c0-2.8-0.1-5.5-0.3-8.3c-0.7-11.4-3-22.5-6.6-32.9c-1.3,10-4.5,19.5-9.2,28C34.1,44.9,18.6,58,0,64.3
			c2.4,13.6,8.4,26,16.8,36.2c4.4-5.3,10.6-9.1,17.7-10.4c-1.6,2-3.3,3.9-5,5.7c-5.3,5.4-11.3,10.1-18,13.9C7.9,111.8,4,113.6,0,115
			c4.2,2.9,8.6,5.4,13.3,7.4c8.2,3.6,17.2,5.8,26.6,6.4c1.6,0.1,3.2,0.1,4.8,0.1c2.8,0,5.6-0.1,8.3-0.4c-0.8-2.8-1.3-5.7-1.3-8.8
			c0-8.6,3.6-16.3,9.3-21.8c5.7,5.5,9.3,13.2,9.3,21.8c0,3-0.4,6-1.3,8.8c2.7,0.3,5.5,0.4,8.3,0.4c1.6,0,3.2,0,4.8-0.1
			c9.4-0.6,18.4-2.8,26.6-6.4c4.7-2,9.1-4.5,13.3-7.4c-4-1.5-7.9-3.3-11.6-5.3C103.9,106,97.8,101.3,92.6,95.8z"/>
		</svg>
		`
	},
	{
		logo: `
		<svg x="0px" y="0px" width="129.8px" height="129.8px" viewBox="0 0 129.8 129.8">
		<g>
			<path class="logos_svg" d="M108.4,49.2c-0.1,0-0.1-0.1-0.2-0.1c6.4-3.7,13.8-5.8,21.6-5.8c-23.9,0-43.3-19.4-43.3-43.3c0,7.9-2.1,15.3-5.8,21.6
				c0-0.1-0.1-0.1-0.1-0.2c-7-12-24.4-12-31.5,0c0,0.1-0.1,0.1-0.1,0.2C45.4,15.3,43.3,7.9,43.3,0c0,23.9-19.4,43.3-43.3,43.3
				c7.9,0,15.3,2.1,21.6,5.8c-0.1,0-0.1,0.1-0.2,0.1c-12,7-12,24.4,0,31.5c0.1,0,0.1,0.1,0.2,0.1C15.3,84.4,7.9,86.6,0,86.6
				c23.9,0,43.3,19.4,43.3,43.3c0-7.9,2.1-15.3,5.8-21.6c0,0.1,0.1,0.1,0.1,0.2c7,12,24.4,12,31.5,0c0-0.1,0.1-0.1,0.1-0.2
				c3.7,6.4,5.8,13.8,5.8,21.6c0-23.9,19.4-43.3,43.3-43.3c-7.9,0-15.3-2.1-21.6-5.8c0.1,0,0.1-0.1,0.2-0.1
				C120.4,73.6,120.4,56.2,108.4,49.2z M73,88.7c-0.1,0-0.1,0-0.2,0.1c-5.1,1.7-10.7,1.7-15.8,0c-0.1,0-0.1,0-0.2-0.1
				c-7.4-2.4-13.2-8.2-15.7-15.7c0-0.1,0-0.1-0.1-0.2c-1.7-5.1-1.7-10.7,0-15.8c0-0.1,0-0.1,0.1-0.2c2.4-7.4,8.2-13.2,15.7-15.7
				c0.1,0,0.1,0,0.2-0.1c5.1-1.7,10.7-1.7,15.8,0c0.1,0,0.1,0,0.2,0.1c7.4,2.4,13.2,8.2,15.7,15.7c0,0.1,0,0.1,0.1,0.2
				c1.7,5.1,1.7,10.7,0,15.8c0,0.1,0,0.1-0.1,0.2C86.3,80.5,80.5,86.3,73,88.7z"/>
			<circle class="logos_svg" cx="64.9" cy="64.9" r="17.5"/>
		</g>
		</svg>
		`
	},
	{
		logo: `
		<svg x="0px" y="0px" width="137.2px" height="91px" viewBox="0 0 137.2 91">
		<g>
			<path class="logos_svg" d="M102.4,0.7c-0.2-0.2-0.5-0.4-0.7-0.6c0,0,7.9,8.5-5.4,26.1c-6-0.1-11.9,2.2-16.2,6.4c-1.9,1.9-3.7,4.2-5,7.3l-6.3-7.3
				l-6.6,7.6c-1.4-3.2-3.2-5.7-5.2-7.7c-4.3-4.2-10.2-6.4-16.2-6.4C27.6,8.5,35.5,0,35.5,0c-0.2,0.2-0.5,0.4-0.7,0.6
				c-7.4,6.5-11.1,15.6-11.1,24.8c0,7.5,2.5,15.1,7.7,21.3c0.3,0,0.6,0.1,0.9,0.1l23.4,1.7c-0.1,0.2-0.3,0.5-0.4,0.7
				c-7.5,13.3-5.1,30.6,6.8,41.1c0.2,0.2,0.5,0.4,0.7,0.6c0,0-8.2-8.8,6.1-27c14.2,18.2,6.1,27,6.1,27c0.2-0.2,0.5-0.4,0.7-0.6
				c11.9-10.5,14.4-27.8,6.8-41.1c-0.1-0.3-0.3-0.5-0.4-0.8l22.9-1.7c0.3,0,0.6,0,0.9-0.1c5.2-6.2,7.7-13.7,7.7-21.3
				C113.5,16.3,109.8,7.2,102.4,0.7z"/>
			<path class="logos_svg" d="M137.1,11.8c0-0.3,0-0.6-0.1-0.9c0,0-0.4,11.6-22.2,14.6c0,7.5-2.6,15.1-7.7,21.3C124.9,45,138.2,29.6,137.1,11.8z"/>
			<path class="logos_svg" d="M0.1,10.8c0,0.3,0,0.6-0.1,0.9C-1,29.5,12.3,44.9,30,46.7c-5.1-6.2-7.7-13.7-7.7-21.3C0.5,22.3,0.1,10.8,0.1,10.8z"/>
		</g>
		</svg>
		`
	},
	{
		logo: `
		<svg x="0px" y="0px" width="84.7px" height="123.5px" viewBox="0 0 84.7 123.5">
		<g>
			<path class="logos_svg" d="M19.2,71.2v-3.6H16v3.6H3.2H0.1v3.2V88v3.2v4.4v3.2v4.4v3.2h3.2h78.2v17.2h3.2v-17.2v-3.2h-3.2H3.2v-4.4h81.4v-3.2H3.2
				v-4.4h78.2h3.2V88l0,0V74.4v-3.2h-3.2H19.2z M81.5,88L81.5,88H3.2V74.4H16h3.2h62.3V88z"/>
			<rect class="logos_svg" x="0.1" y="109.9" width="3.2" height="13.6"/>
			<rect class="logos_svg" x="20.4" y="109.9" width="3.2" height="13.6"/>
			<rect class="logos_svg" x="40.8" y="109.9" width="3.2" height="13.6"/>
			<rect class="logos_svg" x="61.1" y="109.9" width="3.2" height="13.6"/>
			<polygon class="logos_svg" points="43.9,23.9 84.6,23.9 84.6,20.7 43.9,20.7 43.9,15.6 84.6,15.6 84.6,12.4 43.9,12.4 43.9,7.2 84.6,7.2 84.6,4 
				43.9,4 43.9,0 40.7,0 40.7,4 0,4 0,7.2 40.7,7.2 40.7,12.4 0,12.4 0,15.6 40.7,15.6 40.7,20.7 0,20.7 0,23.9 40.7,23.9 	"/>
			<path class="logos_svg" d="M84.7,29.1L84.7,29.1l-84.6,0v3.2h0v4.4h0v3.2h0v4.8h0v3.2h0v8.4h3.2v-8.4h78.2v8.4h3.2L84.7,29.1L84.7,29.1z M3.3,32.3
				h78.2v4.4H3.3V32.3z M3.3,44.7v-4.8h78.2v4.8H3.3z"/>
		</g>
		</svg>
		`
	},
	{
		logo: `
		<svg x="0px" y="0px" width="119.6px" height="124px" viewBox="0 0 119.6 124">
		<g>
			<path class="logos_svg" d="M101.4,48.3c-0.1,0-0.2-0.1-0.2-0.1c-5.4-2.7-10.9-5.3-16.5-7.6c-3.8-1.6-7.6-3.1-11.5-4.6c4,1.6,7.4,4,10.1,7
				c6.2,7,8.5,17.2,5,26.6l0,0c-0.7,1.8-0.1,3.8,1.5,5c0.7,0.5,1.4,1.1,2.1,1.6c3.4,2.7,3.8,7.7,0.9,10.9c0,0,0,0-0.1,0.1c0,0,0,0,0,0
				c0,0,0,0-0.1,0c-2.4,2.7-5.2,4.9-8.3,6.8c0,0,0,0,0,0c-5.9,3.6-12.7,5.7-19.7,5.9c-4,0.1-8-0.4-11.9-1.5c-0.1,0-0.2,0.1-0.3,0.1
				c-0.9-0.4-1.7-0.7-2.6-1C31.8,90.9,21.6,72.3,24.7,54c0.4-2.4,1-4.8,1.9-7.2c3.2-8.7,9.2-15.6,16.6-20.1c9.9-6,22.4-7.5,34.1-3.2
				c4.9,1.8,9.2,4.5,12.8,7.8c1.4-2.2,2.9-4.4,4.5-6.6c0,0,0.1-0.1,0.1-0.1c4.2-6.1,2.1-14.6-4.6-17.9c-23.3-11.8-52.5-8-72,11.5
				C-6,42.4-6.1,81.7,18.1,105.9c23.7,23.8,63.5,24.1,87.5,0.5c3.4-3.3,6.3-6.9,8.7-10.7C125,79.3,118.9,57.2,101.4,48.3z"/>
			<ellipse class="logos_svg" transform="matrix(0.7503 -0.6611 0.6611 0.7503 -24.1405 57.2085)" cx="63.7" cy="60.6" rx="17.2" ry="17.2"/>
		</g>
		</svg>
		`
	},
	{
		logo: `
		<svg x="0px" y="0px" width="137.5px" height="30.1px" viewBox="0 0 137.5 30.1">
		<g>
			<g>
				<polygon class="logos_svg" points="79.8,7.5 91.1,7.5 88.4,0 85.6,0 77.1,0 61.1,0 52.9,0 49.8,0 47.1,7.5 58.4,7.5 59.1,5.6 79.1,5.6 		"/>
				<polygon class="logos_svg" points="80.1,8.2 74.9,8.2 74.4,6.6 63.1,6.6 63.6,8.2 47.1,8.2 47.1,13.8 78.7,13.8 78.4,15.1 49.1,15.1 49.1,20.8 
					77.1,20.8 86.8,20.8 88.4,20.8 90,13.8 91.1,9.4 91.1,8.2 		"/>
				<polygon class="logos_svg" points="58.4,30.1 47.1,30.1 49.1,21.6 60.4,21.6 		"/>
				<polygon class="logos_svg" points="77.5,24.4 72.6,24.4 72.6,21.6 61.3,21.6 61.3,24.4 61.3,30.1 72.6,30.1 78.8,30.1 		"/>
				<polygon class="logos_svg" points="89.1,21.6 85.4,21.6 77.8,21.6 73.6,21.6 73.6,23.6 78.3,23.6 78.5,24.4 79.8,30.1 82.5,30.1 91.1,30.1 		"/>
			</g>
			<g>
				
					<rect class="logos_svg" x="103.5" y="-4.3" transform="matrix(-7.416213e-02 0.9972 -0.9972 -7.416213e-02 119.4959 -100.3959)" width="5.6" height="19.1"/>
				
					<rect class="logos_svg" x="103" y="3.2" transform="matrix(-7.416213e-02 0.9972 -0.9972 -7.416213e-02 126.3721 -91.7886)" width="5.6" height="19.1"/>
				<polygon class="logos_svg" points="137.5,17.5 129,17.5 129,0 117.8,0 117.8,17.5 93.2,17.5 93.2,23.1 98.2,23.1 96.5,30.1 107.8,30.1 109.4,23.1 
					121.3,23.1 122.9,30.1 134.2,30.1 132.6,23.1 137.5,23.1 		"/>
			</g>
			<g>
				<polygon class="logos_svg" points="18.5,12.2 17.7,12.2 17.7,0 6.4,0 6.4,12.2 5.6,12.2 5.6,2.8 0,2.8 0,27.3 5.6,27.3 5.6,17.9 6.4,17.9 6.4,30.1 
					17.7,30.1 17.7,17.9 18.5,17.9 		"/>
				<polygon class="logos_svg" points="37,24.4 37,23.1 41.6,23.1 41.6,17.5 37,17.5 37,16 25.7,16 25.7,17.5 21,17.5 21,23.1 25.7,23.1 25.7,24.4 
					18.9,24.4 18.9,30.1 43.7,30.1 43.7,24.4 		"/>
				<path class="logos_svg" d="M43.7,12.9l0-2.8c-1,0-2,0-2.9-0.1C42,9.3,43,8.7,43.7,8.1V5.6v-1V0H18.9v4.6v1v2.5c0.8,0.5,1.7,1.2,2.9,1.8
					C20.9,10,20,10.1,19,10.1l0,2.8l0,2.8c0,0,0,0,0,0c0.2,0,0.3,0,0.5,0c4.4,0,8.4-0.7,11.9-1.7c3.5,1,7.5,1.7,11.9,1.7
					c0.2,0,0.3,0,0.5,0c0,0,0,0,0,0L43.7,12.9z M31.3,8.1c-1.6-0.5-3-1.1-4.2-1.7c-0.5-0.2-1-0.5-1.4-0.7H37c-0.4,0.2-0.9,0.5-1.4,0.7
					C34.4,6.9,32.9,7.5,31.3,8.1z"/>
			</g>
		</g>
		</svg>
		`
	},
	{
		logo: `
		<svg x="0px" y="0px" width="134px" height="109.4px" viewBox="0 0 134 109.4">
		<g>
			<path class="logos_svg" d="M87.8,50.3c1.4-1.4,2.7-3,3.8-4.6c1.1-1.6,2-3.3,2.7-5.1c0.7-1.8,1.3-3.6,1.6-5.5c0.4-1.8,0.5-3.7,0.5-5.6
				c0-1.9-0.2-3.8-0.5-5.6c-0.4-1.9-0.9-3.7-1.6-5.5c-0.7-1.8-1.6-3.5-2.7-5.1c-1.1-1.7-2.4-3.2-3.8-4.6s-3-2.7-4.6-3.8
				c-1.6-1.1-3.3-2-5.1-2.7c-1.8-0.7-3.6-1.3-5.5-1.6C70.8,0.2,68.9,0,67,0c-1.9,0-3.8,0.2-5.6,0.5c-1.9,0.4-3.7,0.9-5.5,1.6
				c-1.8,0.7-3.5,1.6-5.1,2.7c-1.7,1.1-3.2,2.4-4.6,3.8c-1.4,1.4-2.7,3-3.8,4.6c-1.1,1.6-2,3.3-2.7,5.1c-0.5,1.1-0.8,2.3-1.2,3.5
				c-0.2,0.7-0.3,1.3-0.5,2c-0.4,1.8-0.5,3.7-0.5,5.6c0,1.9,0.2,3.8,0.5,5.6c0.4,1.9,0.9,3.7,1.6,5.5c0.7,1.8,1.6,3.5,2.7,5.1
				c1.1,1.7,2.4,3.2,3.8,4.6l10.4,10.4L39,78.2h20.8l7.1-7.1l7.1,7.1h20.8L77.4,60.7L67,50.3L56.6,39.9c-5.7-5.7-5.7-15.1,0-20.8
				s15.1-5.7,20.8,0c5.7,5.7,5.7,15.1,0,20.8L87.8,50.3z"/>
			<g>
				<path class="logos_svg" d="M7.7,109.4c-5.1,0-7.7-2.2-7.7-6.5v-7.5c0-4.4,2.6-6.5,7.7-6.5h15.9c5.1,0,7.7,2.2,7.7,6.5v7.5c0,4.3-2.6,6.5-7.7,6.5H7.7
					z M7.7,93.6c-1.8,0-2.7,0.6-2.7,1.8v7.5c0,1.2,0.9,1.7,2.7,1.7h15.9c1.8,0,2.7-0.6,2.7-1.7v-7.5c0-1.2-0.9-1.8-2.7-1.8H7.7z"/>
				<path class="logos_svg" d="M33.9,88.9h24.7c4,0,5.9,1.7,5.9,5v1.8c0,3.5-2,5.2-5.9,5.2h-1.7l8.5,8.5h-6.9l-8-8.2H38.9v8.2h-5V88.9z M38.9,93.6v3.3
					h19.8c0.7,0,1-0.3,1-0.8v-1.7c0-0.5-0.4-0.8-1.2-0.8H38.9z"/>
				<path class="logos_svg" d="M95.5,104.6v4.8H72.8c-4.1,0-6.2-1.7-6.2-5V93.9c0-3.3,2.1-5,6.2-5h22.6v4.8H72.8c-0.8,0-1.2,0.3-1.2,1v2.1h23.6v4.8H71.6
					v2.1c0,0.7,0.4,1,1.2,1H95.5z"/>
				<path class="logos_svg" d="M97.8,88.9h5v20.5h-5V88.9z"/>
				<path class="logos_svg" d="M111.6,109.4c-4.1,0-6.2-1.7-6.2-5V88.9h5v14.8c0,0.7,0.4,1,1.2,1H134v4.8H111.6z"/>
			</g>
		</g>
		</svg>
		`
	},
	{
		logo: `
		<svg x="0px" y="0px" width="81.7px" height="135.7px" viewBox="0 0 81.7 135.7">
		<g>
			<path class="logos_svg" d="M23,35c0.1,0.1,0.2,0.1,0.3,0.1c0.2,0,0.4-0.1,0.5-0.3c1.5-2.5,2.5-5.4,2.8-8.3c0.5-4.5-0.6-8.2-1.6-10.5
				c-0.1-0.3-0.5-0.5-0.8-0.3c-0.3,0.1-0.5,0.5-0.3,0.8c0.9,2.2,2,5.6,1.5,9.8c-0.3,2.7-1.2,5.5-2.6,7.8C22.6,34.4,22.7,34.8,23,35z"
				/>
			<path class="logos_svg" d="M23.2,53c-0.2,0.3-0.2,0.7,0.1,0.9c0.1,0.1,0.2,0.1,0.4,0.1c0.2,0,0.4-0.1,0.5-0.3c5.4-7.2,9.3-15.2,11.8-23.8
				c2.7-9.6,3.5-19.4,2.4-29.3C38.3,0.2,38,0,37.6,0c-0.3,0-0.6,0.4-0.6,0.7c1.2,9.7,0.4,19.4-2.3,28.8C32.3,38,28.4,45.9,23.2,53z"/>
			<path class="logos_svg" d="M44.7,33.1c3.2-3.1,7.6-2.5,8.4-2.3c0.3,0.1,0.7-0.2,0.7-0.5c0.1-0.3-0.2-0.7-0.5-0.7c-1-0.2-5.9-0.8-9.5,2.7
				c-1.1,1-3.6,3.8-2.9,6.8c0.4,1.9,1.9,3.6,4.3,4.8c1.7,0.9,3.6,1.3,5.3,1.3c1.2,0,2.3-0.2,3.3-0.6c3.8-1.7,4.3-8.3,5.1-21.2
				c0.1-1.2,0.2-2.9-1-4.3c-3-3.5-11.5-0.2-14.1,0.9c-0.3,0.1-0.5,0.5-0.3,0.8c0.1,0.3,0.5,0.5,0.8,0.3C49,19,55,17.5,57,19.8
				c0.8,0.9,0.7,2.3,0.7,3.4c-0.8,12.1-1.3,18.7-4.4,20.2c-2,0.9-4.9,0.6-7.5-0.7c-2-1-3.3-2.4-3.6-3.9C41.6,36.3,44,33.8,44.7,33.1z"
				/>
			<path class="logos_svg" d="M30.8,93c0.1,0.3,0.3,0.5,0.6,0.5c0,0,0.1,0,0.1,0c0.3-0.1,0.5-0.4,0.5-0.8c-0.5-2.1-1.2-4.1-2.1-6.1
				c-1.2-2.6-2.7-5-4.5-7.2c-0.2-0.3-0.6-0.3-0.9-0.1c-0.3,0.2-0.3,0.6-0.1,0.9c1.7,2.1,3.2,4.5,4.3,6.9C29.7,89,30.4,91,30.8,93z"/>
			<path class="logos_svg" d="M47.7,72.7c-5.3-0.3-11.5,2-14.6,5.5c-1.3,1.4-2.1,3-2.9,4.7c-0.2,0.3,0,0.7,0.3,0.8c0.3,0.2,0.7,0,0.8-0.3
				c0.8-1.7,1.6-3.2,2.7-4.5c2.9-3.2,8.6-5.4,13.6-5.1c2.2,0.1,5.2,0.8,7,3.2c1.3,1.8,1.8,4.5,1.3,7.4c-0.6,3.2-2.2,6.1-4.4,7.6
				c-2,1.4-4,1.2-6.2,1c-1.8-0.2-3.8-0.3-6.1,0.3c-6.2,1.9-10.7,9.5-13.5,22.6c-0.1,0.3,0.1,0.7,0.5,0.7c0,0,0.1,0,0.1,0
				c0.3,0,0.6-0.2,0.6-0.5c2.7-12.6,6.9-19.9,12.6-21.6c2-0.6,3.8-0.5,5.6-0.3c2.3,0.2,4.6,0.4,7.1-1.2c2.5-1.7,4.3-4.8,4.9-8.4
				c0.6-3.2,0-6.3-1.5-8.4C54,74.2,51.2,72.9,47.7,72.7z"/>
			<path class="logos_svg" d="M47.4,99.6c-4.2-1-7.9,0-10.2,0.9c-0.3,0.1-0.5,0.5-0.3,0.8c0.1,0.3,0.5,0.5,0.8,0.3c2.2-0.9,5.5-1.8,9.4-0.9
				c1.6,0.4,5,1.1,7,3.9c1.8,2.6,1.5,5.7,1,9.2c-0.4,3.1-1.1,5.1-1.9,7.2c-0.8,2.1-1.6,4.4-2.1,7.9c-0.3,2-0.4,4-0.3,6
				c0,0.3,0.3,0.6,0.6,0.6c0,0,0,0,0,0c0.3,0,0.6-0.3,0.6-0.7c-0.1-1.9,0-3.9,0.3-5.8c0.4-3.4,1.2-5.5,2-7.6c0.8-2.1,1.6-4.2,2-7.5
				c0.4-3.6,0.9-7.1-1.2-10.1C52.9,100.8,49.2,100,47.4,99.6z"/>
			<path class="logos_svg" d="M34.5,118.4c0,0.3,0.3,0.6,0.6,0.6c0,0,0.1,0,0.1,0c0.3,0,0.6-0.4,0.6-0.7c-0.1-1.2-0.4-2.4-0.9-3.5
				c-0.5-1.4-1.3-2.6-2.2-3.8c-0.2-0.3-0.6-0.3-0.9-0.1c-0.3,0.2-0.3,0.6-0.1,0.9c0.8,1,1.5,2.2,2,3.5
				C34.1,116.2,34.4,117.3,34.5,118.4z"/>
			<path class="logos_svg" d="M48.8,109c-1.5-1.4-3.6-1.5-5.6-1.3c0.1-1.2,0.1-2.4,0.2-3.6c0-0.3-0.3-0.6-0.6-0.6c0,0,0,0,0,0c-0.3,0-0.6,0.3-0.6,0.6
				c0,1.3-0.1,2.6-0.2,3.9c-0.8,0.2-1.6,0.4-2.1,0.6c-1.9,0.7-3.7,1.7-5.3,3.2c-0.3,0.2-0.3,0.6,0,0.9c0.2,0.3,0.6,0.3,0.9,0
				c1.5-1.4,3.1-2.3,4.9-3c0.3-0.1,0.9-0.3,1.6-0.5c-0.1,1.4-0.3,2.8-0.5,4.2c-0.2,1.2-0.4,2.3-0.6,3.5c-1.5,0.1-2.9-0.2-3.8-0.5
				c-0.3-0.1-0.7,0.1-0.8,0.4c-0.1,0.3,0.1,0.7,0.4,0.8c0.9,0.3,2.1,0.6,3.5,0.6c0.1,0,0.2,0,0.4,0c-0.1,0.7-0.3,1.3-0.4,2
				c-3.1,0.5-5.6,1.8-7.1,3c-0.3,0.2-0.3,0.6-0.1,0.9c0.1,0.2,0.3,0.3,0.5,0.3c0.1,0,0.3,0,0.4-0.1c1.4-1.1,3.7-2.4,6.8-2.8
				c0,0,0,0,0.1,0c0.1,0,0.2,0,0.2,0c0.2,0,0.3,0,0.5,0c1.7-0.1,3.4,0.1,5,0.6c0.3,0.1,0.7-0.1,0.8-0.4c0.1-0.3-0.1-0.7-0.4-0.8
				c-1.7-0.5-3.5-0.7-5.3-0.6c0.2-0.7,0.3-1.3,0.4-2c0.3,0,0.6-0.1,0.9-0.2c0.9-0.2,3.3-1,5.4-3.1c0.8-0.9,2.1-2.2,1.9-3.8
				C49.8,109.9,49.1,109.2,48.8,109z M47.2,114c-1.8,1.9-3.9,2.5-4.7,2.7c-0.1,0-0.2,0-0.3,0.1c0.2-1,0.4-2.1,0.5-3.1
				c0.2-1.5,0.4-3.1,0.5-4.6c1.7-0.2,3.6-0.2,4.8,0.9c0.2,0.2,0.7,0.6,0.8,1.3C48.9,112.3,48,113.2,47.2,114z"/>
			<path class="logos_svg" d="M47.7,118.3c-0.3-0.2-0.7-0.2-0.9,0c-0.2,0.3-0.2,0.7,0,0.9c0.4,0.4,0.9,1,1.1,1.9c0.2,0.8,0.1,1.6,0,2
				c-0.1,0.3,0.1,0.7,0.4,0.8c0.1,0,0.1,0,0.2,0c0.3,0,0.5-0.2,0.6-0.5c0.2-0.6,0.3-1.6,0-2.7C48.8,119.6,48.2,118.7,47.7,118.3z"/>
			<path class="logos_svg" d="M39.6,91.2c0,0,0.1,0,0.1,0c0.3,0,0.5-0.2,0.6-0.5c0.3-1.4,0.4-2.9,0.1-4.3c-0.3-1.8-1-3.5-2-4.9c-0.2-0.3-0.6-0.4-0.9-0.2
				c-0.3,0.2-0.4,0.6-0.2,0.9c0.9,1.3,1.5,2.8,1.8,4.4c0.2,1.3,0.2,2.5-0.1,3.8C39,90.8,39.3,91.1,39.6,91.2z"/>
			<path class="logos_svg" d="M48.5,89.5C48.5,89.5,48.6,89.5,48.5,89.5c0.4,0,0.7-0.2,0.7-0.5c0.4-2.6,0.2-5.1-0.4-7.6c-0.3-1.2-0.7-2.4-1.3-3.5
				c-0.2-0.3-0.5-0.4-0.8-0.3c-0.3,0.2-0.4,0.5-0.3,0.8c0.5,1,0.9,2.1,1.2,3.3c0.6,2.3,0.7,4.7,0.4,7.1C47.9,89.2,48.2,89.5,48.5,89.5
				z"/>
			<polygon class="logos_svg" points="64.7,69.2 59.5,74.4 40.8,50.9 22.2,74.4 16.9,69.2 0,86.2 0.4,86.6 16.9,70 22.2,75.2 40.8,51.7 59.5,75.2 
				64.7,70 81.3,86.6 81.7,86.2 	"/>
			<path class="logos_svg" d="M0.9,68.1c0.2,0.2,0.4,0.3,0.6,0.4c0.2,0.1,0.6,0.1,0.9,0.1c0.2,0,0.5,0,0.7-0.1c0.1,0,0.3-0.1,0.5-0.1
				c0.1,0,0.2-0.1,0.2-0.1c0,0,0.1-0.1,0.1-0.1c0-0.1,0-0.2,0-0.5l0-0.4c0-0.1,0-0.2,0-0.2c0,0,0.1-0.1,0.2-0.1l0.2,0c0,0,0.1,0,0.2,0
				c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0,0,0,0c-0.1,0-0.1,0-0.2,0l-0.3,0l-0.3,0c-0.2,0-0.3,0-0.4,0c-0.3,0-0.6,0-0.7,0c0,0,0,0,0,0
				l0,0.1c0,0,0,0,0,0l0.1,0l0.1,0c0.2,0,0.4,0.1,0.4,0.1c0,0.1,0.1,0.2,0.1,0.6c0,0.2,0,0.3,0,0.4c0,0.1-0.1,0.1-0.1,0.2
				c-0.1,0.1-0.2,0.1-0.3,0.1c-0.1,0-0.3,0-0.4,0c-0.2,0-0.3,0-0.4,0c-0.2,0-0.3-0.1-0.4-0.2c-0.2-0.1-0.3-0.3-0.5-0.4
				c-0.1-0.2-0.2-0.3-0.3-0.5C1,67.2,1,67,1,66.7c0-0.4,0.1-0.8,0.3-1c0.2-0.3,0.4-0.5,0.7-0.6c0.2-0.1,0.5-0.1,0.8-0.1
				c0.3,0,0.5,0.1,0.7,0.2c0.2,0.1,0.4,0.4,0.5,0.7l0.1,0c0,0,0-0.1,0-0.3l0-0.4c-0.1-0.1-0.3-0.1-0.5-0.2c-0.2-0.1-0.5-0.1-0.8-0.1
				c-0.5,0-0.9,0.1-1.1,0.2c-0.4,0.2-0.7,0.5-0.9,0.8c-0.2,0.3-0.3,0.7-0.3,1c0,0.3,0.1,0.5,0.2,0.8C0.6,67.8,0.8,67.9,0.9,68.1z"/>
			<path class="logos_svg" d="M16.8,65l0.2,0c0,0,0.1,0,0.1,0c0,0,0,0.1,0,0.2v0.4l0,0.7l0,0.8v0.2c0,0.3,0,0.5,0.1,0.6c0,0.1,0.1,0.2,0.3,0.3
				c0.1,0.1,0.3,0.2,0.5,0.3c0.1,0,0.3,0.1,0.6,0.1c0.2,0,0.4,0,0.5-0.1c0.2-0.1,0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.2,0.2-0.3
				c0.1-0.1,0.1-0.3,0.1-0.5c0-0.1,0-0.4,0-0.8l0-0.3l0-0.2l0-0.6c0-0.2,0-0.3,0-0.4c0-0.1,0-0.2,0.1-0.2c0,0,0.2-0.1,0.4-0.1l0-0.1
				l-0.1,0l-0.5,0c0,0-0.1,0-0.2,0c-0.1,0-0.2,0-0.2,0c-0.1,0-0.1,0-0.2,0c0,0,0,0.1,0,0.1c0.2,0,0.4,0.1,0.4,0.1c0,0,0.1,0.1,0.1,0.1
				c0,0.1,0,0.2,0,0.5l0,0.8c0,0,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2V67c0,0.1,0,0.2,0,0.2c0,0.3,0,0.6-0.1,0.8c-0.1,0.2-0.2,0.3-0.4,0.4
				c-0.2,0.1-0.4,0.1-0.8,0.1c-0.1,0-0.3,0-0.5-0.1c-0.2-0.1-0.3-0.1-0.3-0.2c-0.1-0.1-0.1-0.2-0.2-0.3c0-0.1,0-0.5-0.1-1l0-1.4l0-0.3
				c0,0,0-0.1,0.1-0.1c0.1,0,0.1-0.1,0.2-0.1c0,0,0,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0,0,0-0.1c0,0,0,0,0-0.1l-0.8,0h-0.5
				l-0.2,0h-0.1c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0C16.6,65,16.7,65,16.8,65z"/>
			<path class="logos_svg" d="M32.7,68.5c0,0-0.1,0-0.1,0l0,0.1h0.1l0.7,0l0.4,0c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0,0.4,0v0c0,0,0,0,0-0.1c0,0-0.1,0-0.2,0
				c0,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.1-0.1c0,0,0-0.1-0.1-0.1c0-0.1,0-0.2,0-0.4c0-0.1,0-0.2,0-0.3V67l0-1.3l0-0.4c0-0.1,0-0.2,0-0.2
				c0,0,0,0,0.1,0c0,0,0.2,0,0.3-0.1c0.1,0,0.2,0,0.2,0c0,0,0-0.1,0-0.1c-0.3,0-0.6,0-0.8,0l-0.8,0h-0.2c0,0,0,0.1,0,0.1
				c0,0,0.1,0,0.3,0c0.2,0,0.3,0,0.3,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.3,0,0.4c0,0,0,0.1,0,0.1l0,0.2l0,0.1v0.3l0,0.2l0,0.2l0,0.3v0.3
				l0,0.3c0,0.4,0,0.7-0.1,0.7C33.2,68.4,33,68.4,32.7,68.5z"/>
			<path class="logos_svg" d="M48.2,68.4c-0.1,0.1-0.3,0.1-0.4,0.1c-0.2,0-0.5-0.1-0.7-0.3c-0.2-0.2-0.3-0.4-0.3-0.6c0,0,0-0.1,0-0.1c0,0,0,0,0,0
				c0,0,0,0,0,0c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0,0.6c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0.1,0.1,0.3,0.1c0.2,0.1,0.5,0.1,0.7,0.1
				c0.3,0,0.5,0,0.7-0.1c0.2-0.1,0.3-0.2,0.4-0.4c0.1-0.2,0.2-0.4,0.2-0.6c0-0.2,0-0.3-0.1-0.4c-0.1-0.1-0.2-0.2-0.3-0.4
				c-0.1-0.1-0.4-0.2-0.7-0.4c-0.4-0.2-0.7-0.4-0.8-0.5C47,65.8,47,65.7,47,65.6c0-0.2,0.1-0.3,0.2-0.4c0.1-0.1,0.3-0.2,0.5-0.2
				c0.2,0,0.4,0.1,0.5,0.2c0.1,0.1,0.3,0.3,0.3,0.6l0,0c0,0,0,0,0,0c0,0,0-0.1,0-0.2l0-0.3c0,0,0-0.1,0-0.2c-0.1-0.1-0.2-0.1-0.3-0.2
				c-0.1,0-0.3-0.1-0.5-0.1c-0.4,0-0.6,0.1-0.9,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4c0.1,0.1,0.2,0.3,0.4,0.4
				c0.1,0.1,0.3,0.2,0.6,0.4c0.3,0.2,0.6,0.3,0.7,0.5c0.1,0.1,0.2,0.3,0.2,0.4c0,0.1,0,0.2-0.1,0.4C48.4,68.3,48.3,68.4,48.2,68.4z"/>
			<path class="logos_svg" d="M61.8,68.6c0.1,0,0.2,0,0.3,0c0.1,0,0.3,0,0.4,0c0.1,0,0.1,0,0.1,0c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0,0l0,0
				c0,0-0.1,0-0.2-0.1c-0.1,0-0.2,0-0.2-0.1c0,0,0,0-0.1-0.1c0-0.1,0-0.3,0-0.5c0,0,0-0.1,0-0.2c0-0.3,0-0.5,0-0.5l0-0.2
				c0,0,0-0.1,0-0.1c0,0,0,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.2,0c0.1,0,0.3,0,0.4,0c0.1,0,0.2,0,0.3,0c0,0,0.1,0,0.2,0
				c0.5,0,0.7,0,0.8,0c0,0,0,0,0,0c0,0.3,0,0.6,0,0.7c0,0,0,0.1,0,0.2l0,0.4c0,0.1,0,0.1-0.1,0.2c-0.1,0.1-0.1,0.1-0.2,0.1
				c-0.1,0-0.2,0-0.3,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0.2,0,0.4-0.1,0.5-0.1c0.1,0,0.2,0,0.4,0c0.2,0,0.5,0,0.7,0.1c0,0,0,0,0,0l0,0
				l0,0c0,0,0,0,0-0.1c0,0-0.1,0-0.2-0.1l-0.2-0.1c0,0,0,0-0.1-0.1c0-0.1,0-0.1,0-0.3l0-0.4c0-0.2,0-0.3,0-0.4l0-0.5l0-0.5l0-0.3
				l0-0.2v-0.3c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1c0-0.1,0-0.1,0.1-0.2c0,0,0.1,0,0.2,0c0.1,0,0.2,0,0.3-0.1c0,0,0-0.1,0-0.1
				c-0.3,0-0.5,0-0.6,0c-0.2,0-0.4,0-0.5,0l-0.2,0c0,0-0.1,0-0.1,0c0,0,0,0.1,0,0.1c0,0,0.1,0,0.1,0c0.2,0,0.3,0,0.3,0c0,0,0,0,0,0.1
				c0,0.1,0,0.2,0,0.5c0,0.3,0,0.6,0,1c0,0-0.1,0-0.2,0l-0.7,0l-0.3,0l-0.4,0c0,0,0,0-0.1,0c-0.2,0-0.4,0-0.4,0l0-0.3c0-0.3,0-0.7,0-1
				c0-0.2,0-0.3,0.1-0.3c0,0,0.2-0.1,0.4-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0l-0.3,0
				c0,0-0.1,0-0.1,0c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0,0.1,0,0.2,0c0.1,0,0.2,0,0.2,0c0,0,0,0,0,0.1c0,0,0,0.1,0,0.3c0,0.1,0,0.2,0,0.3
				c0,0.1,0,0.3,0,0.4v0.8c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0,0.4l0,0.2l0,0.2l0,0.2c0,0.1,0,0.1,0,0.2c0,0,0,0.1-0.1,0.1
				c0,0-0.1,0-0.3,0.1c-0.1,0-0.2,0-0.2,0.1l0,0.1c0,0,0.1,0,0.1,0C61.5,68.6,61.7,68.6,61.8,68.6z"/>
			<path class="logos_svg" d="M77.7,65l0.2,0c0,0,0.1,0,0.1,0c0,0,0,0.1,0,0.2v0.4l0,0.7l0,0.8v0.2c0,0.3,0,0.5,0.1,0.6c0,0.1,0.1,0.2,0.3,0.3
				c0.1,0.1,0.3,0.2,0.5,0.3c0.1,0,0.3,0.1,0.6,0.1c0.2,0,0.4,0,0.5-0.1c0.2-0.1,0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.2,0.2-0.3
				c0.1-0.1,0.1-0.3,0.1-0.5c0-0.1,0-0.4,0-0.8l0-0.3l0-0.2l0-0.6c0-0.2,0-0.3,0-0.4c0-0.1,0-0.2,0.1-0.2c0,0,0.2-0.1,0.4-0.1l0-0.1
				l-0.1,0l-0.5,0c0,0-0.1,0-0.2,0c-0.1,0-0.2,0-0.2,0c-0.1,0-0.1,0-0.2,0c0,0,0,0.1,0,0.1c0.2,0,0.4,0.1,0.4,0.1c0,0,0.1,0.1,0.1,0.1
				c0,0.1,0,0.2,0,0.5l0,0.8c0,0,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2V67c0,0.1,0,0.2,0,0.2c0,0.3,0,0.6-0.1,0.8c-0.1,0.2-0.2,0.3-0.4,0.4
				c-0.2,0.1-0.4,0.1-0.8,0.1c-0.1,0-0.3,0-0.5-0.1c-0.2-0.1-0.3-0.1-0.3-0.2c-0.1-0.1-0.1-0.2-0.2-0.3c0-0.1,0-0.5-0.1-1l0-1.4l0-0.3
				c0,0,0-0.1,0.1-0.1c0.1,0,0.1-0.1,0.2-0.1c0,0,0,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0,0,0-0.1c0,0,0,0,0-0.1l-0.8,0h-0.5
				l-0.2,0h-0.1c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0C77.5,65,77.5,65,77.7,65z"/>
		</g>
		</svg>
		`
	}
]

export default LOGOS;


