// import React from 'react';
// import { useLanguage } from '../LanguageContext';
// import translations from '../translations';
// // 图片懒加载组件
// import LazyImageWithSkeleton from '../utils/LazyImageWithSkeleton';

// import './PortfolioPage.scss';

// import SHU from '../tools/shu.webp';

// import P1 from '../tools/1.webp';
// import P2 from '../tools/2.webp';
// import P3 from '../tools/3.webp';
// import P4 from '../tools/4.webp';
// import P5 from '../tools/5.webp';
// import P6 from '../tools/6.webp';
// import P7 from '../tools/7.webp';
// import P8 from '../tools/8.webp';

// // 长海报
// import long_poster from '../tools/long2.jpg';

// function PortfolioPage() {
//     const { language } = useLanguage();

//     // 区分移动端浏览器和PC端浏览器
//     const userAgent = navigator.userAgent;
//     const items = ['Apple', 'Banana', 'Orange', 'Grapes', 'Apple', 'Apple', 'Apple', 'Apple'];
//     const items3 = [SHU, SHU, SHU, SHU, SHU, SHU, SHU, SHU,];
//     const items4 = ['Apple', 'Banana', 'Orange', 'Grapes', 'Apple', 'Apple', 'Apple', 'Apple'];

//     const pics = [P1, P2, P3, P4, P5, P6, P7, P8];

//     const longposter = [long_poster, long_poster, long_poster, long_poster, long_poster, long_poster, long_poster, long_poster];

//     function handleWorks(event, index) {
//         console.log("当前打开的是" + index + "号弹窗");
//         console.log(event.target.tagName);
//         const el = document.querySelector(`.previewdialog${index}`);

//         if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent)) {
//             return;
//         }
//         if (el) {
//             el.showModal();
//         } else {
//             console.warn("元素未找到，无法显示弹窗");
//         }
//     }
//     function handleWorkPreview(event, index) {
//         console.log(event.target.tagName);
//         if (event.target.tagName.toLowerCase() === "dialog") {
//             const el1 = document.querySelector(`.previewdialog${index}`);

//             el1.close();
//             console.log("当前关闭的是" + index + "号弹窗");
//         }
//     }


//     function handleWorksshu(event, index) {
//         console.log("当前打开的是" + index + "号弹窗");
//         console.log(event.target.tagName);
//         const el = document.querySelector(`.shu-previewdialog${index}`);
//         if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent)) {
//             return;
//         }

//         if (el) {
//             el.showModal();
//         } else {
//             console.error("元素未找到，无法显示弹窗");
//         }
//     }


//     function handleWorkPreviewshu(event, index) {
//         console.log(event.target.tagName);
//         if (event.target.tagName.toLowerCase() === "dialog") {
//             const el1 = document.querySelector(`.shu-previewdialog${index}`);

//             el1.close();
//             console.log("当前关闭的是" + index + "号弹窗");
//         }
//     }

//     function handleWorkPreviewshuClosed(event, index) {
//         const el1 = document.querySelector(`.shu-previewdialog${index}`);
//         el1.close();
//         console.log("当前关闭的是" + index + "号弹窗");
//     }


//     function handleWorkslong(event, index) {
//         console.log("当前打开的是" + index + "号弹窗");
//         console.log(event.target.tagName);
//         const el = document.querySelector(`.longposter-previewdialog${index}`);
//         el.showModal();
//     }

//     function handleWorkPreviewlong(event, index) {
//         console.log(event.target.tagName);

//         if (event.target.tagName.toLowerCase() === "dialog") {
//             // 在关闭弹窗时，将滚动条重置到顶部
//             const container = document.querySelector(`.long-imgpreview_container${index}`);
//             container.scrollTop = 0;
//             console.log(container);
//             // 关闭弹窗
//             const el1 = document.querySelector(`.longposter-previewdialog${index}`);
//             el1.close();
//             console.log("当前关闭的是" + index + "号弹窗");
//         }
//     }

//     return (
//         <div>
//             <div className='main-container-1'>

//                 <span className='title-1'>{translations[language].profile_logo}</span>
//                 <div className='poster'>
//                     {items.map((item, index) => (
//                         <div className='item' key={index}>
//                             {/* <div className="icon">
//                                 <div className="iconBox">
//                                     <span className="material-symbols-outlined">
//                                     </span>
//                                 </div>
//                             </div> */}
//                             <img className='picture' src={item} alt="" />
//                         </div>
//                     ))}
//                 </div>

//                 <span className='title-1'>{translations[language].profile_poster}</span>
//                 <div className='poster-shu'>
//                     {
//                         items3.map((item, index) => (
//                             <div className='item-shu' key={index}>
//                                 <div className='item-shu-inside' onClick={(e) => handleWorksshu(e, index)}>
//                                     {/* // 遮罩层 */}
//                                     <div className='mask-shu'>
//                                         <span>创作者：GreatyBai</span>
//                                         <span>创作时间：05/11/2024 16:54:10(UTC)</span>
//                                     </div>
//                                     <LazyImageWithSkeleton src={items3[index]} alt={`SHU ${index + 1}`} />
//                                 </div>

//                                 <dialog onClick={(e) => handleWorkPreviewshu(e, index)} className={`shu-previewdialog${index} shu-previewdialog`}>
//                                     <div className='shu-imgpreview_container'>
//                                         {/* 关闭弹窗按钮 */}
//                                         <div className='shu-closebtn' onClick={(e) => handleWorkPreviewshuClosed(e, index)}>
//                                             <svg x="0px" y="0px" width="24px" height="24px" viewBox="0 0 24 24" >
//                                                 <polygon className="st1" points="24,0 22.6,0 12,10.6 1.4,0 0,0 0,1.4 10.6,12 0,22.6 0,24 1.4,24 12,13.4 22.6,24 24,24 24,22.6 
//                                                 13.4,12 24,1.4 "/>
//                                             </svg>

//                                         </div>
//                                         <img className='shu-imgpreview' src={items3[index]} alt='' />
//                                     </div>
//                                 </dialog>
//                             </div>
//                         ))
//                     }
//                 </div>

//                 <span className='title-1'>{translations[language].profile_banner}</span>
//                 <div className='aftereffect'>
//                     {items4.map((item, index) => (
//                         <div className='item-aftereffect' key={index}>
//                             <div className='item-aftereffect-inside' onClick={(e) => handleWorks(e, index)}>
//                                 <LazyImageWithSkeleton src={pics[index]} alt={`Banner ${index + 1}`} />
//                             </div>

//                             <dialog onClick={(e) => handleWorkPreview(e, index)} className={`previewdialog${index} previewdialog`}>
//                                 <div className='imgpreview_container'>
//                                     <img className='imgpreview' src={pics[index]} alt='' />
//                                 </div>
//                             </dialog>
//                         </div>
//                     ))}
//                 </div>

//                 <span className='title-1'>{translations[language].profile_long_poster}</span>
//                 <div className='longposter'>
//                     {
//                         longposter.map((item, index) => (
//                             <div className='longposter-item' key={index}>
//                                 <div className='item-longposter-inside' onClick={(e) => handleWorkslong(e, index)}>
//                                     <LazyImageWithSkeleton src={longposter[index]} alt={`long_poster ${index + 1}`} />
//                                 </div>

//                                 <dialog onClick={(e) => handleWorkPreviewlong(e, index)} className={`longposter-previewdialog${index} longposter-previewdialog`}>
//                                     <div className='mouse-effect'>
//                                         <svg className='mouse-effect-svg' x="0px" y="0px" width="52px" height="72px" viewBox="0 0 52 72" >
//                                             <path className="st3" d="M26,71L26,71C12.2,71,1,59.8,1,46V26C1,12.2,12.2,1,26,1h0c13.8,0,25,11.2,25,25v20C51,59.8,39.8,71,26,71z" />
//                                             <line className="st3" x1="26" y1="1" x2="26" y2="41.5" />
//                                             <path fill='#000' d="M26,37.3L26,37.3c-4.4,0-8-3.6-8-8v-14c0-4.4,3.6-8,8-8h0c4.4,0,8,3.6,8,8v14C34,33.7,30.4,37.3,26,37.3z" />
//                                             <circle className="st4" cx="26" cy="15.3" r="4" />
//                                         </svg>
//                                     </div>
//                                     <div className={`long-imgpreview_container${index} long-imgpreview_container`}>
//                                         <img className='longposter-imgpreview' src={longposter[index]} alt='' />
//                                     </div>

//                                 </dialog>
//                             </div>
//                         ))
//                     }
//                 </div>

//                 <span className='title-1'>{translations[language].profile_creative}</span>
//                 <div className='creative-aftereffect'>
//                     {items4.map((item, index) => (
//                         <div className='creative-item-aftereffect' key={index}></div>
//                     ))}
//                 </div>


//             </div>
//         </div>
//     )
// }

// export default PortfolioPage













// import React, { useRef } from 'react';
// import { useLanguage } from '../LanguageContext';
// import translations from '../translations';
// import LazyImageWithSkeleton from '../utils/LazyImageWithSkeleton';
// import './PortfolioPage.scss';

// import SHU from '../tools/shu.webp';
// import P1 from '../tools/1.webp';
// import P2 from '../tools/2.webp';
// import P3 from '../tools/3.webp';
// import P4 from '../tools/4.webp';
// import P5 from '../tools/5.webp';
// import P6 from '../tools/6.webp';
// import P7 from '../tools/7.webp';
// import P8 from '../tools/8.webp';
// import long_poster from '../tools/long2.jpg';

// function PortfolioPage() {
//     const { language } = useLanguage();

//     // Refs to store multiple dialog elements
//     const dialogRefs = useRef([]);
//     const shuDialogRefs = useRef([]);
//     const longPosterDialogRefs = useRef([]);

//     const isMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

//     // General function to open a dialog
//     const openDialog = (index, type) => {
//         if (isMobileDevice) return;

//         const refArray = type === 'longposter' ? longPosterDialogRefs.current : type === 'shu' ? shuDialogRefs.current : dialogRefs.current;
//         const dialog = refArray[index];
//         dialog?.showModal();
//     };

//     // General function to close a dialog
//     const closeDialog = (index, type) => {
//         const refArray = type === 'longposter' ? longPosterDialogRefs.current : type === 'shu' ? shuDialogRefs.current : dialogRefs.current;
//         const dialog = refArray[index];
//         dialog?.close();
//     };

//     const items4 = ['Apple', 'Banana', 'Orange', 'Grapes', 'Apple', 'Apple', 'Apple', 'Apple'];
//     const pics = [P1, P2, P3, P4, P5, P6, P7, P8];
//     const items3 = Array(8).fill(SHU);
//     const longposter = Array(8).fill(long_poster);

//     return (
//         <div>
//             <div className='main-container-1'>

//                 <span className='title-1'>{translations[language].profile_logo}</span>
//                 <div className='poster'>
//                     {pics.map((item, index) => (
//                         <div className='item' key={index}>
//                             <img className='picture' src={item} alt="" />
//                         </div>
//                     ))}
//                 </div>

//                 <span className='title-1'>{translations[language].profile_poster}</span>
//                 <div className='poster-shu'>
//                     {items3.map((item, index) => (
//                         <div className='item-shu' key={index}>
//                             <div className='item-shu-inside' onClick={() => openDialog(index, 'shu')}>
//                                 <div className='mask-shu'>
//                                     <span>创作者：GreatyBai</span>
//                                     <span>创作时间：05/11/2024 16:54:10(UTC)</span>
//                                 </div>
//                                 <LazyImageWithSkeleton src={item} alt={`SHU ${index + 1}`} />
//                             </div>

//                             <dialog ref={(el) => shuDialogRefs.current[index] = el} onClick={(e) => e.target.tagName.toLowerCase() === "dialog" && closeDialog(index, 'shu')} className={`shu-previewdialog`}>
//                                 <div className='shu-imgpreview_container'>
//                                     <div className='shu-closebtn' onClick={() => closeDialog(index, 'shu')}>
//                                         <svg x="0px" y="0px" width="24px" height="24px" viewBox="0 0 24 24">
//                                             <polygon className="st1" points="24,0 22.6,0 12,10.6 1.4,0 0,0 0,1.4 10.6,12 0,22.6 0,24 1.4,24 12,13.4 22.6,24 24,24 24,22.6 13.4,12 24,1.4 " />
//                                         </svg>
//                                     </div>
//                                     <img className='shu-imgpreview' src={item} alt='' />
//                                 </div>
//                             </dialog>
//                         </div>
//                     ))}
//                 </div>

//                 <span className='title-1'>{translations[language].profile_banner}</span>
//                 <div className='aftereffect'>
//                     {pics.map((item, index) => (
//                         <div className='item-aftereffect' key={index}>
//                             <div className='item-aftereffect-inside' onClick={() => openDialog(index, 'dialog')}>
//                                 <LazyImageWithSkeleton src={item} alt={`Banner ${index + 1}`} />
//                             </div>

//                             <dialog ref={(el) => dialogRefs.current[index] = el} onClick={(e) => e.target.tagName.toLowerCase() === "dialog" && closeDialog(index, 'dialog')} className={`previewdialog`}>
//                                 <div className='imgpreview_container'>
//                                     <img className='imgpreview' src={item} alt='' />
//                                 </div>
//                             </dialog>
//                         </div>
//                     ))}
//                 </div>

//                 <span className='title-1'>{translations[language].profile_long_poster}</span>
//                 <div className='longposter'>
//                     {longposter.map((item, index) => (
//                         <div className='longposter-item' key={index}>
//                             <div className='item-longposter-inside' onClick={() => openDialog(index, 'longposter')}>
//                                 <LazyImageWithSkeleton src={item} alt={`long_poster ${index + 1}`} />
//                             </div>

//                             <dialog ref={(el) => longPosterDialogRefs.current[index] = el} onClick={(e) => e.target.tagName.toLowerCase() === "dialog" && closeDialog(index, 'longposter')} className={`longposter-previewdialog`}>
//                                 <div className='mouse-effect'>
//                                     <svg className='mouse-effect-svg' x="0px" y="0px" width="52px" height="72px" viewBox="0 0 52 72">
//                                         <path className="st3" d="M26,71L26,71C12.2,71,1,59.8,1,46V26C1,12.2,12.2,1,26,1h0c13.8,0,25,11.2,25,25v20C51,59.8,39.8,71,26,71z" />
//                                         <line className="st3" x1="26" y1="1" x2="26" y2="41.5" />
//                                         <path fill='#000' d="M26,37.3L26,37.3c-4.4,0-8-3.6-8-8v-14c0-4.4,3.6-8,8-8h0c4.4,0,8,3.6,8,8v14C34,33.7,30.4,37.3,26,37.3z" />
//                                         <circle className="st4" cx="26" cy="15.3" r="4" />
//                                     </svg>
//                                 </div>
//                                 <div className='long-imgpreview_container'>
//                                     <img className='longposter-imgpreview' src={item} alt='' />
//                                 </div>
//                             </dialog>
//                         </div>
//                     ))}
//                 </div>


//                 <span className='title-1'>{translations[language].profile_creative}</span>
//                 <div className='creative-aftereffect'>
//                     {items4.map((item, index) => (
//                         <div className='creative-item-aftereffect' key={index}></div>
//                     ))}
//                 </div>
//             </div>
//         </div>
//     );
// }

// export default PortfolioPage;









import React, { useRef } from 'react';
import { useLanguage } from '../LanguageContext';
import translations from '../translations';
import LazyImageWithSkeleton from '../utils/LazyImageWithSkeleton';
import './PortfolioPage.scss';

// 引入json文件
import LOGOS from '../utils/logos';
import LogoCreater from '../utils/LogoCreater';

import SHU from '../tools/shu.webp';
import P1 from '../tools/1.webp';
import P2 from '../tools/2.webp';
import P3 from '../tools/3.webp';
import P4 from '../tools/4.webp';
import P5 from '../tools/5.webp';
import P6 from '../tools/6.webp';
import P7 from '../tools/7.webp';
import P8 from '../tools/8.webp';
import long_poster from '../tools/long2.jpg';

function PortfolioPage() {
    const { language } = useLanguage();

    // Refs to store multiple dialog elements
    const dialogRefs = useRef([]);
    const shuDialogRefs = useRef([]);
    const longPosterDialogRefs = useRef([]);

    // Check if the device is mobile
    const isMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

    // General function to open a dialog
    const openDialog = (index, type) => {
        // Only allow `dialogRefs` and `shuDialogRefs` dialogs to open on non-mobile devices
        if (isMobileDevice && type !== 'longposter') return;

        const refArray = type === 'longposter' ? longPosterDialogRefs.current : type === 'shu' ? shuDialogRefs.current : dialogRefs.current;
        const dialog = refArray[index];
        dialog?.showModal();
    };

    // General function to close a dialog
    const closeDialog = (index, type) => {
        const refArray = type === 'longposter' ? longPosterDialogRefs.current : type === 'shu' ? shuDialogRefs.current : dialogRefs.current;
        const dialog = refArray[index];
        dialog?.close();
    };

    const pics = [P1, P2, P3, P4, P5, P6, P7, P8];
    const items3 = Array(8).fill(SHU);
    const items4 = Array(8).fill();
    const longposter = Array(8).fill(long_poster);


    return (
        <div>
            <div className='main-container-1'>

                <span className='title-1'>{translations[language].profile_logo}</span>
                <div className='poster'>
                    {pics.map((item, index) => (
                        <div className='item' key={index}>
                            <LogoCreater svgString={LOGOS[index].logo} />
                        </div>
                    ))}
                </div>

                <span className='title-1'>{translations[language].profile_poster}</span>
                <div className='poster-shu'>
                    {items3.map((item, index) => (
                        <div className='item-shu' key={index}>
                            <div className='item-shu-inside' onClick={() => openDialog(index, 'shu')}>
                                <div className='mask-shu'>
                                    <span>创作者：GreatyBai</span>
                                    <span>创作时间：05/11/2024 16:54:10(UTC)</span>
                                </div>
                                <LazyImageWithSkeleton src={item} alt={`SHU ${index + 1}`} />
                            </div>

                            <dialog ref={(el) => shuDialogRefs.current[index] = el} onClick={(e) => e.target.tagName.toLowerCase() === "dialog" && closeDialog(index, 'shu')} className={`shu-previewdialog`}>
                                <div className='shu-imgpreview_container'>
                                    <div className='shu-closebtn' onClick={() => closeDialog(index, 'shu')}>
                                        <svg x="0px" y="0px" width="24px" height="24px" viewBox="0 0 24 24">
                                            <polygon className="st1" points="24,0 22.6,0 12,10.6 1.4,0 0,0 0,1.4 10.6,12 0,22.6 0,24 1.4,24 12,13.4 22.6,24 24,24 24,22.6 13.4,12 24,1.4 " />
                                        </svg>
                                    </div>
                                    <img className='shu-imgpreview' src={item} alt='' />
                                </div>
                            </dialog>
                        </div>
                    ))}
                </div>

                <span className='title-1'>{translations[language].profile_banner}</span>
                <div className='aftereffect'>
                    {pics.map((item, index) => (
                        <div className='item-aftereffect' key={index}>
                            <div className='item-aftereffect-inside' onClick={() => openDialog(index, 'dialog')}>
                                <LazyImageWithSkeleton src={item} alt={`Banner ${index + 1}`} />
                            </div>

                            <dialog ref={(el) => dialogRefs.current[index] = el} onClick={(e) => e.target.tagName.toLowerCase() === "dialog" && closeDialog(index, 'dialog')} className={`previewdialog`}>
                                <div className='imgpreview_container'>
                                    <img className='imgpreview' src={item} alt='' />
                                </div>
                            </dialog>
                        </div>
                    ))}
                </div>

                <span className='title-1'>{translations[language].profile_long_poster}</span>
                <div className='longposter'>
                    {longposter.map((item, index) => (
                        <div className='longposter-item' key={index}>
                            <div className='item-longposter-inside' onClick={() => openDialog(index, 'longposter')}>
                                <LazyImageWithSkeleton src={item} alt={`long_poster ${index + 1}`} />
                            </div>

                            <dialog ref={(el) => longPosterDialogRefs.current[index] = el} onClick={(e) => e.target.tagName.toLowerCase() === "dialog" && closeDialog(index, 'longposter')} className={`longposter-previewdialog`}>
                                <div className='mouse-effect'>
                                    <svg className='mouse-effect-svg' x="0px" y="0px" width="52px" height="72px" viewBox="0 0 52 72">
                                        <path className="st3" d="M26,71L26,71C12.2,71,1,59.8,1,46V26C1,12.2,12.2,1,26,1h0c13.8,0,25,11.2,25,25v20C51,59.8,39.8,71,26,71z" />
                                        <line className="st3" x1="26" y1="1" x2="26" y2="41.5" />
                                        <path fill='#000' d="M26,37.3L26,37.3c-4.4,0-8-3.6-8-8v-14c0-4.4,3.6-8,8-8h0c4.4,0,8,3.6,8,8v14C34,33.7,30.4,37.3,26,37.3z" />
                                        <circle className="st4" cx="26" cy="15.3" r="4" />
                                    </svg>
                                </div>
                                <div className='long-imgpreview_container'>
                                    <img className='longposter-imgpreview' src={item} alt='' />
                                </div>
                            </dialog>
                        </div>
                    ))}
                </div>

                <span className='title-1'>{translations[language].profile_creative}</span>
                <div className='creative-aftereffect'>
                    {items4.map((item, index) => (
                        <div className='creative-item-aftereffect' key={index}></div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default PortfolioPage;
