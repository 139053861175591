import React, { useEffect, useRef, useState } from 'react';
import './LazyImageWithSkeleton.scss';
function LazyImageWithSkeleton({ src, alt }) {
    const imgRef = useRef();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        const img = entry.target;
                        img.src = img.dataset.src;
                        observer.unobserve(img);
                    }
                });
            },
            { rootMargin: '100px' } // 提前100px加载
        );

        const imgElement = imgRef.current;

        if (imgElement) {
            observer.observe(imgElement);
        }

        return () => {
            if (imgElement) {
                observer.unobserve(imgElement);
            }
            // 彻底断开observer的引用，防止内存泄漏
            observer.disconnect();
        };
    }, []);

    return (
        <div className="image-wrapper">
            {isLoading && <div className="skeleton-loader">

                <svg x="0px" y="0px" width="60px" height="60px" viewBox="0 0 60 60">

                    <g>
                        <path d="M50,0H10C4.5,0,0,4.5,0,10v34.6V50c0,5.5,4.5,10,10,10h40c5.5,0,10-4.5,10-10V26.8V10C60,4.5,55.5,0,50,0z" />
                        <path className="st5" d="M10,60h40c5.5,0,10-4.5,10-10V26.8c-0.9-2-1.9-3.8-3.1-5.6C41.8-2.4,25,55.9,15.4,40.3
		C10.8,32.7,4.8,37.6,0,44.6V50C0,55.5,4.5,60,10,60z"/>
                        <circle className="st6" cx="14" cy="12" r="5" />
                    </g>
                </svg>

            </div>}
            <img
                ref={imgRef}
                data-src={src}
                alt={alt}
                onLoad={() => setIsLoading(false)}
                className={ `skeleton_img ${isLoading ? 'hidden' : ''} `}
            />
        </div>
    );
}

export default LazyImageWithSkeleton;